<template>
  <CForm autocomplete="off">
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.name"
          name="name"
          label="Kennel Name *"
          placeholder="Kennel Name"
          :isValid="!errors.name ? null : false"
          :invalidFeedback="errors.name"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.club"
          name="club"
          label="Club"
          placeholder="Club"
          :isValid="!errors.club ? null : false"
          :invalidFeedback="errors.club"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.address"
          name="address"
          label="Address *"
          placeholder="Address"
          :isValid="!errors.address ? null : false"
          :invalidFeedback="errors.address"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.city"
          name="city"
          label="City *"
          placeholder="City"
          :isValid="!errors.city ? null : false"
          :invalidFeedback="errors.city"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.state"
          name="state"
          label="State *"
          placeholder="State"
          :isValid="!errors.state ? null : false"
          :invalidFeedback="errors.state"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.country"
          name="country"
          label="Country *"
          placeholder="Country"
          :isValid="!errors.country ? null : false"
          :invalidFeedback="errors.country"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.zip"
          name="zip"
          label="Postal/Zip Code *"
          placeholder="Postal/Zip Code"
          :isValid="!errors.zip ? null : false"
          :invalidFeedback="errors.zip"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.email"
          name="email"
          label="Email"
          placeholder="Email"
          :isValid="!errors.email ? null : false"
          :invalidFeedback="errors.email"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.phone"
          name="phone"
          label="Phone"
          placeholder="Phone"
          :isValid="!errors.phone ? null : false"
          :invalidFeedback="errors.phone"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInputCheckbox
          name="is_active"
          :checked="item.is_active"
          @update:checked="onInputChanged"
          label="Is Active"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import inputFields from '../inputFields'
export default {
  name: 'Form',
  props: {
    data: {
      type: Object,
      defaultValue: {}
    },
    errors: {
      type: Object,
      defaultValue: {}
    }
  },
  data () {
    const defaultFields = Object.keys(inputFields).reduce((data, key) => {
      const { defaultValue } = inputFields[key]
      data[key] = defaultValue
      return data
    }, {})
    const item = Object.assign({}, defaultFields, this.data || {})
    return {
      item
    }
  },
  methods: {
    preventSubmit (event) {
      event.preventSubmit()
      event.preventDefault()
      event.stopPropagation()
    },
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      this.onHandleChange()
    },
    onHandleChange () {
      this.$emit('itemChange', this.item)
    }
  }
}
</script>
